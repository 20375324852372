












































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'Chem1301Lab3Discussion3Fr',
  components: {CalculationInput, STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        step18Calc: null,
        step18: null,
        step20: null,
        step20Calc: null,
        step20c: null,
        step22: null,
        step22b: null,
        step24: null,
        step24b: null,
        step26: null,
        step28: null,
        step28b: null,
        step31: null,
        step32Calc1: null,
        step32Calc2: null,
        step32: null,
        step34: null,
        step36Calc1: null,
        step36Calc2: null,
        step36: null,
      },
      rows: [
        {
          step: '18 a)',
          input: 'step18Calc',
          calc: true,
          question: "Quel est le pH d'une solution de 0,1 M de HCl?",
        },
        {
          step: '18 b)',
          input: 'step18',
          calc: false,
          question:
            "Dans cette étape, on vous demande de mesurer le pH de l'acide acétique. Le pH que vous obtenez est-il raisonnable? Est-il cohérent avec le type d'acide que vous associez à l'acide acétique?",
        },
        {
          step: '20 a)',
          input: 'step20',
          calc: false,
          question:
            "Attendez-vous à ce que l'acétate de sodium présente un comportement acide ou basique lorsqu'il se dissout dans l'eau? Si oui, quelle partie de la substance contribue à ce comportement?",
        },
        {
          step: '20 b)',
          input: 'step20Calc',
          calc: true,
          question: "Quel est le pH d'une solution de 0,1 M de NaOH?",
        },
        {
          step: '20 c)',
          input: 'step20c',
          calc: false,
          question:
            "Attendez-vous à ce que l'acétate de sodium présente un comportement acide ou basique lorsqu'il se dissout dans l'eau ? Si oui, quelle partie de la substance contribue à ce comportement?",
        },
        {
          step: '22 a)',
          input: 'step22',
          calc: false,
          question:
            "Dans cette étape, on vous demande de mesurer le pH lorsque vous mélangez les solutions d'acide acétique et d'acétate de sodium. Vous attendiez-vous du pH que vous avez obtenu?",
        },
        {
          step: '22 b)',
          input: 'step22b',
          calc: false,
          question:
            "Étant donné que le Ka de l'acide acétique est de 1,8*10^-5, essayez de calculer le pH du tampon en utilisant les concentrations initiales de l'acide et du sel. Comment cette valeur se compare-t-elle à la valeur que vous avez mesurée? [Facultatif]",
        },
        {
          step: '24 a)',
          input: 'step24',
          calc: false,
          question:
            "À cette étape, vous avez ajouté une petite quantité d'acide fort au tampon. Quel effet pensez-vous que cette addition aura sur le pH du tampon? Ce que vous pensiez est-il cohérent avec ce que vous avez observé expérimentalement?",
        },
        {
          step: '24 b)',
          input: 'step24b',
          calc: false,
          question:
            "Essayez de calculer le pH du tampon après l'ajout de l'acide fort. Dans votre calcul, vous pouvez considérer que 20 gouttes sont équivalentes à 1 mL. La valeur que vous calculez est-elle proche de la valeur que vous avez mesurée? Qu'est-ce que cela vous indique? [Facultatif]",
        },
        {
          step: '26',
          input: 'step26',
          calc: false,
          question:
            "À l'étape précédente, vous avez ajouté 20 gouttes (~ 1 mL) de HCl 0,1 mol/L au tampon. À cette étape, vous avez ajouté 20 gouttes (~ 1 mL) de NaOH 0,1 mol/L au tampon. Quel effet pensez-vous que l'ajout de cette base forte aura sur le pH du tampon? Pourquoi?",
        },
        {
          step: '28 a)',
          input: 'step28',
          calc: false,
          question:
            'À cette étape, vous avez ajouté une quantité supplémentaire de base forte au tampon. Quel effet pensez-vous que cette addition aura sur le pH du tampon ? Ce que vous pensiez est-il cohérent avec ce que vous avez observé expérimentalement?',
        },
        {
          step: '28 b)',
          input: 'step28b',
          calc: false,
          question:
            "Essayez de calculer le pH du tampon après l'ajout de la base forte. Dans votre calcul, vous pouvez considérer que 20 gouttes sont équivalentes à 1 mL. La valeur que vous calculez est-elle proche de la valeur que vous avez mesurée? Qu'est-ce que cela vous indique?",
        },
        {
          step: '31',
          input: 'step31',
          calc: false,
          question:
            "Qu'avez-vous observé à propos du pH de l'eau distillée ? Est-ce ce à quoi vous vous attendiez pour le pH de l'eau ? Pouvez-vous expliquer le pH observé?",
        },
        {
          step: '32 a)',
          input: 'step32Calc1',
          calc: true,
          question:
            "Calculez la concentration de l'acide fort en le diluant (en supposant que vous avez ajouté exactement 1 mL d'acide à 20 mL d'eau).",
        },
        {
          step: '32 b)',
          input: 'step32Calc2',
          calc: true,
          question: "Calculez le pH attendu de la solution d'acide diluée.",
        },
        {
          step: '32 c)',
          input: 'step32',
          calc: false,
          question:
            "Comment cette valeur de pH se compare-t-elle à la valeur du pH que vous avez mesurée à cette étape? Comment cette valeur se compare-t-elle au pH de la solution tampon (Étape 24) après l'ajout de la même quantité d'acide fort ? Que cela vous démontre-t-il?",
        },
        {
          step: '34',
          input: 'step34',
          calc: false,
          question:
            "À l'étape précédente, vous avez ajouté 20 gouttes (~ 1 mL) de HCl 0,1 mol/L à l'eau distillée. À cette étape, vous avez ajouté 20 gouttes (~ 1 mL) de NaOH 0,1 mol/L à la solution acide. Quel effet pensez-vous que l'ajout de cette base forte aura sur le pH? Pourquoi?",
        },
        {
          step: '36 a)',
          input: 'step36Calc1',
          calc: true,
          question:
            "Calculez la concentration de la base forte en la diluant (en supposant que vous avez ajouté exactement 1 mL de base à 20 mL d'eau).",
        },
        {
          step: '36 b)',
          input: 'step36Calc2',
          calc: true,
          question: 'Calculez le pH attendu de la solution de base diluée.',
        },
        {
          step: '36 c)',
          input: 'step36',
          calc: false,
          question:
            "Comment cette valeur de pH se compare-t-elle à la valeur du pH que vous avez mesurée à cette étape? Comment cette valeur se compare-t-elle au pH de la solution tampon (Étape 28) après l'ajout de la même quantité de base forte? Que cela vous démontre-t-il?",
        },
      ],
    };
  },
});
